import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image'
import React from 'react';
import Row from 'react-bootstrap/Row';
import logo from './assets/preis_architekti_logo.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <Container>
        <Row>
          <Col/>
          <Col>
            <Image src={logo} alt="logo" fluid={true} />
          </Col>
          <Col/>
        </Row>
      </Container>
      </header>
    </div>
  );
}

export default App;
